<template>
  <div class="flex flex-col gap-6">
    <h1 class="text-xl sm:text-2xl font-semibold text-gray-900">Jouw bestuurderspas</h1>

    <div class="card">
      <h2>Laatste bestuurderspas</h2>
      <UILoading v-if="latestImages === null"></UILoading>
      <small v-else-if="!latestImages?.length" class="lg:col-span-3 xl:col-span-4">Nog geen bestuurderspas gevonden.</small>
      <div v-else class="grid grid-cols-2 gap-4 max-w-xl md:max-w-2xl lg:max-w-3xl my-4">
        <a class="shadow-lg rounded p-4 block hover:shadow-xl cursor-pointer" v-for="(src, index) in latestImages" :key="src" :href="src" target="_blank" rel="noopener noreferrer">
          <img class="border border-gray-200" :src="src" :alt="index === 0 ? 'VOORKANT' : 'ACHTERKANT'">
          <p class="font-bold text-center block mt-2">{{ index === 0 ? 'VOORKANT' : 'ACHTERKANT' }}</p>
        </a>
      </div>
    </div>

    <div class="card">
      <h2>Nieuwe bestuurderspas uploaden</h2>
      <div class="text-center" v-show="loading">
        <UILoading></UILoading>
        <small>Geduld, het opladen kan even duren.</small>
      </div>

      <div v-if="done">Bedankt voor het opladen van je laatste bestuurderspas!</div>
      <form v-else v-show="!loading" method="POST" action="#" enctype="multipart/form-data" @submit.prevent="onSubmit" class="flex flex-col gap-6 items-start">
        <div>
          <label class="font-bold" for="voorkant">Voorkant bestuurderspas</label>
          <input onclick="this.value = null;" class="ml-2" type="file" name="voorkant" id="voorkant" accept="image/*" capture @change="handleOnChangeVoorkant">
        </div>

        <div>
          <label class="font-bold" for="achterkant">Achterkant bestuurderspas</label>
          <input onclick="this.value = null;" class="ml-2" type="file" name="achterkant" id="achterkant" accept="image/*" capture @change="handleOnChangeAchterkant">
        </div>

        <button class="btn success" :disabled="!voorkant?.name || !achterkant?.name" type="submit">Doorsturen</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import UILoading from '@/components/UI/Loading.vue'

const axios = inject('axios')

const latestImages = ref(null)
const loadLatest = () => {
  axios.get('/api/dashboard/bestuurderspassen/latest').then(({ data }) => {
    latestImages.value = data?.VOORKANT && data?.ACHTERKANT ? [data.VOORKANT, data.ACHTERKANT] : []
    console.log({ data })
  })
}
onMounted(loadLatest)

const done = ref(false)
const loading = ref(false)
const voorkant = ref(null)
const achterkant = ref(null)

const handleOnChangeVoorkant = (event) => {
  if (event?.target?.files?.length > 0) voorkant.value = event.target.files[0]
}
const handleOnChangeAchterkant = (event) => {
  if (event?.target?.files?.length > 0) achterkant.value = event.target.files[0]
}

const onSubmit = async () => {
  const formData = new FormData()
  formData.append('files', voorkant.value, 'VOORKANT.png')
  formData.append('files', achterkant.value, 'ACHTERKANT.png')

  done.value = false
  loading.value = true

  await axios.post('/api/dashboard/bestuurderspassen/upload', formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data;',
      'cache-control': 'no-cache',
    },
  }).then(() => {
    setTimeout(() => {
      loading.value = false
      done.value = true
      loadLatest()
    }, 1000)
  }).catch((error) => {
    alert('Er liep iets verkeerd')
    alert(error)
    alert(String(error))
    loading.value = false
  })

}
</script>
