<template>
  <h1 class="text-xl sm:text-2xl font-semibold text-gray-900">Procedures - Luchthavenvervoer Kevin</h1>

  <ul class="flex flex-col gap-4 m-0 mt-4 w-full max-w-xl">
    <li v-if="false && store.getters.hasMasterPermission">
      <!-- Nothing yet -->
    </li>
    <li class="card">
      <a target="_blank" href="/api/dashboard/crups/algemeen">
        Procedures Algemene
        <i class="fas fa-play rounded-full border-1 p-4"></i>
      </a>
    </li>
    <li v-if="store.getters.hasChauffeurOrOnderaannemerPermission" class="card">
      <a target="_blank" href="/api/dashboard/crups/chauffeur">
        Procedures Chauffeur
        <i class="fas fa-play rounded-full border-1 p-4"></i>
      </a>
    </li>
    <li v-if="false && store.getters.hasChauffeurWithoutOnderaannemerPermission">
      <!-- Nothing yet -->
    </li>
    <li v-if="store.getters.hasKantoorPermission" class="card">
      <a target="_blank" href="/api/dashboard/crups/admin" class="">
        Procedures Administratie
        <i class="fas fa-play rounded-full border-1 p-4"></i>
      </a>
    </li>
    <li v-if="store.getters.hasKantoorPermission" class="card">
      <a target="_blank" href="/api/dashboard/crups/helpdesk" class="">
        Procedures Helpdesk - Kennisbank
        <i class="fas fa-play rounded-full border-1 p-4"></i>
      </a>
    </li>
  </ul>
</template>

<script setup>
import { useStore } from 'vuex'

const store = useStore()
</script>

<style scoped lang="scss">
li {
  @apply rounded shadow hover:shadow-none border border-l-8; // border-l-dashboard-dark;

  a {
    @apply px-2 w-full flex justify-between items-center text-lg font-bold;

    i {
      @apply text-green-500;
    }
  }
}
</style>
